import React from "react";
import styled from "styled-components";
import { Parallax } from "react-scroll-parallax";

import { sizes } from "../base_components/screenSizes";

import BlobOutline from "../../images/bloboutline.svg";
import YellowPatternBlob from "../../images/yellowpatternblob.svg";
import PinkPatternBlob from "../../images/pinkpatternblob.svg";

const BlobHolder = styled.div``;

const OutlineHolder = styled.div`
  position: absolute;
  top: -50px;
  width: 100%;
  transition: all 700ms;

  @media (max-width: ${sizes.large}px) {
    width: 75%;
    right: 0;
  }

  @media (max-width: ${sizes.medium}px) {
    width: 100%;
  }
`;

const PinkHolder = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  transition: all 700ms;

  @media (max-width: ${sizes.large}px) {
    width: 75%;
    right: 0;
  }

  @media (max-width: ${sizes.medium}px) {
    width: 100%;
  }
`;

const YellowHolder = styled.div`
  position: absolute;
  width: 100%;
  transition: all 700ms;

  @media (max-width: ${sizes.large}px) {
    width: 75%;
    right: 0;
  }

  @media (max-width: ${sizes.medium}px) {
    width: 100%;
  }
`;

export const Blob = () => {
  return (
    <BlobHolder>
      <YellowHolder>
        <Parallax y={[-20, 20]}>
          <YellowPatternBlob />
        </Parallax>
      </YellowHolder>

      <PinkHolder>
        <Parallax x={[-20, 20]}>
          <PinkPatternBlob />
        </Parallax>
      </PinkHolder>

      <OutlineHolder>
        <BlobOutline />
      </OutlineHolder>
    </BlobHolder>
  );
};
