import styled from "styled-components";
import React from "react";
import { colorMap } from "./colors";
import { sizes } from "./screenSizes";

export const ImageBackgroundWrapper = styled.div`
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border-radius: 5px;
  max-width: 600px;
`;

export const ImageWrapper = styled.img`
  border-radius: 5px;
  margin-bottom: 0;
`;

export const ImageStyle = props => {
  const { imgSrc, alt } = props;
  return (
    <ImageBackgroundWrapper>
      <ImageWrapper src={imgSrc} alt={alt} />
    </ImageBackgroundWrapper>
  );
};
